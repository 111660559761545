.phone-container {
  margin-top: 2em;
  > a {
    font-size:35px;
  }
  @include breakpoint(medium) {
    > a {
      font-size: 30px;
      margin-left: 1em;
    }
  }
}
