footer {
  font-size: 15px;
  text-align: center;
  background-color: $brown;
  color: $white;
  padding: rem-calc(20 0);
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: darken($white,15%);
    }
  }
  p {
    margin-bottom: rem-calc(10);
    br {
      display: none;
    }
  }
  address {
      margin-bottom: rem-calc(15);
  }
  img {
    margin: 1.5em;
    width: 58px;
    height: 89px;
    @include breakpoint(large) {
      width: 73px;
      height: 112px;
    }
  }
  .data-privacy {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  @include breakpoint(large) {
    font-size: 18px;
    .cell, .data-privacy{
      margin: auto 0;
    }
    address {
      text-align: left;
    }
    .data-privacy {
      span {
        display: none;
      }
    }
    p {
      text-align: right;
      br {
        display: block
      }
    }
  }
}

footer.bgWhite {
  background-color: $white;
  color: $brown;
  a {
    color: $brown;
  }
  .data-privacy {
    opacity: 44%;
  }
}
