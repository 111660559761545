.logo {
  /* padding: rem-calc(20);
  a {
    display: inline-block;
    img {
      width: 200px;
      height: auto; //immer width und height in PX angeben (IE)
    }
  } */
  width: 230px;
  height: 230px;
  @include breakpoint(medium){
    width: 339px;
    height: 339px;
  }
}
