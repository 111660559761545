.poster {
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: rem-calc(230);
    height: rem-calc(230);
    @include breakpoint (large) {
      width: rem-calc(339);
      height: rem-calc(339);
    }
  }
}
