// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

.bg-brown {
  background: $brown;

  a {
    color: $white;
  }
}

.txt-white {
  color: $white;
}

.padding-bottom {
  padding-bottom: 2em;
}

.image {
  @include breakpoint(medium) {
      width: 100vw;
  }
  @include breakpoint(small only) {
    display: block;
    transform: translateX(-20%);
    width: 250vw;
    max-width: none;
    //transform: translate(-50px);
  }
}

h2 {
  margin-bottom: 2em;
}

.no-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
