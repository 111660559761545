.icon-sprites.svg-icon {
  display: none;
}

.svg-icon {
    display: inline-flex;
    align-self: center;
    position: relative;
    height: 1.8em;
    width: 1.8em;
}

.svg-icon svg {
    bottom: -0.1em;
    position: absolute;
    fill: $black;
    height: inherit;
    width: inherit;
    &:hover {
      fill: lighten($black,20%);
    }
}

.phone-icon {
  width: 78px;
  height: 78px;
  @include breakpoint(small only){
    display: block;
    margin: 0 auto 10px auto;
  }
  @include breakpoint(medium){
    width: 57px;
    height: 57px;
    vertical-align: -10px;
    display: inline-block;
  }
}

.arrow-icon {
  z-index: 100;
  position: absolute;
  top: 50px;
  left: 50px;
  @include breakpoint(medium) {
    top: 100px;
    left: 100px;
  }
}
