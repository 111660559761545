@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=3445528b-71df-451e-a312-285946d3e8ea&fontids=5769302,5769331");
    @font-face{
        font-family:"Carnero";
        src:url("../fonts/5769302/0110f74c-cfec-4029-a503-f9c7670b3eaa.woff2") format("woff2"),url("../fonts/5769302/c1c9d552-25c4-425b-93c1-a4098112697b.woff") format("woff");
        font-weight: normal;
    }
    @font-face{
        font-family:"Carnero";
        src:url("../fonts/5769331/34dbee74-6c16-4126-b2a9-c0a1888b8cf0.woff2") format("woff2"),url("../fonts/5769331/a9eb8cad-7402-4f42-8fb3-67ae312fba04.woff") format("woff");
        font-weight: bold;
    }

//global font-size (small, medium, large)
main {
  font-size: rem-calc(18);
  @include breakpoint (large) {
    font-size: rem-calc(22);
  }
}

h1 {
  text-transform: uppercase;
  margin-bottom: 1.2em;
  margin-top: 0;
}

h2 {
  text-transform: uppercase;
}
