.circle-title {
  margin-bottom: 6rem;
  @include breakpoint(medium down) {
    margin-bottom: 2em;
  }
}

.circle-row {
    display: grid;
    > div {
      margin: 1.5em auto;
    }
    @include breakpoint(medium) {
      grid-template-columns: 1fr 1fr;
      margin: 0 10vw; //rather vw instead of em bc font-size is changing at large screen, otherwise it needs another breakpoint
      > div {
        margin: 2em auto 0 auto;
      }
    }
  @include breakpoint(1390) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
    //custom breakpoint bc of 4 buttons, otherwise not nice
    @include breakpoint(1405) {
      margin: 0;
      > div {
        //remove margi again from last and first child
        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
}

.aboutUs-circle-row {
  display: grid;
  img {
    margin-bottom: 1em;
  }
  > div {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
  }
  @include breakpoint(medium) {
    grid-template-columns: 1fr 1fr;
    > div {
      margin: 2em auto 0 auto;
    }
  }
  @include breakpoint(1400) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  b {
    text-transform: uppercase;
  }
  .svg-icon {
    font-size: 0.5em;
    .icon {
      fill: $brown;
    }
  }
}
