section {
  padding: 5.5em 0;
  @include breakpoint(small down) {
    padding: 3em 0;
  }
}

.grid-container {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
}
