.button {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.circle {
  position: relative;
  background: $brown;
  color: $white;
  border-radius: 50%;
  width: rem-calc(230);
  height: rem-calc(230);
  @include breakpoint (large) {
    width: rem-calc(280);
    height: rem-calc(280);
  }

  .circle-txt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: rem-calc(20);
    @include breakpoint (large) {
      font-size: rem-calc(25);
    }
  }
}
